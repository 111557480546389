var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_vm._v(" Full Status History ")]),_c('v-card',[_c('v-card-title',[_vm._v(" Latest Status ")]),_c('v-data-table',{staticClass:"scrollable-table__medium",attrs:{"fixed-header":"","hide-default-footer":"","headers":_vm.invoiceHeaders,"items":_vm.latestStatus,"loading":_vm.loading,"item-key":"id"},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":_vm.colorTheme.progress,"indeterminate":""}})]},proxy:true},{key:"item.billingPeriod",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-wrap"},[_vm._v(" "+_vm._s(_vm.formatPeriod(item.billingPeriod))+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('cell-monetary-value',{attrs:{"value":item.amount}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.id}})]}},{key:"item.accountInvoiceLineItemId",fn:function(ref){
var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.accountInvoiceLineItemId}})]}},{key:"item.sourceAccountId",fn:function(ref){
var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.sourceAccountId}})]}},{key:"item.paidByAccountId",fn:function(ref){
var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.paidByAccountId}})]}}],null,true)})],1),(_vm.latestNote.length > 0)?_c('v-card',[_c('v-card-title',[_vm._v(" Latest Note ")]),_c('v-data-table',{staticClass:"scrollable-table__medium",attrs:{"fixed-header":"","hide-default-footer":"","headers":_vm.noteHeaders,"items":_vm.latestNote,"loading":_vm.loading,"item-key":"id"},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":_vm.colorTheme.progress,"indeterminate":""}})]},proxy:true},{key:"item.billingStatusNoteId",fn:function(ref){
var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.billingStatusNoteId}})]}},{key:"item.eventTime",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatDateTextMonth(item.eventTime)))])]}}],null,true)})],1):_vm._e(),_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" History of changes ")]),_c('v-timeline',{staticClass:"mb-5"},_vm._l((_vm.combinedChanges),function(item){return _c('v-timeline-item',{key:item.id,attrs:{"small":"","color":item.color,"left":""},scopedSlots:_vm._u([{key:"opposite",fn:function(){return [_c('v-card-text',[_vm._v(" "+_vm._s(_vm.getTimeAndAuthor(item))+" ")])]},proxy:true}],null,true)},[(item.operation == 'Insert' || item.operation == 'Delete')?_c('v-card',[_c('span',[_c('v-card-text',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]):(item.operation == 'Update')?_c('v-card',_vm._l((item.changes),function(change,index){return _c('span',{key:index},[_c('v-card-text',[_vm._v(" "+_vm._s(change.column)+" : "+_vm._s(change.oldValue)+" "),_c('span',{style:(_vm.transitionColor)},[_vm._v(" => ")]),_vm._v(" "+_vm._s(change.newValue)+" ")]),_c('v-divider')],1)}),0):_vm._e()],1)}),1),_c('v-row',[_c('v-btn',{staticClass:"mb-2 ml-2",attrs:{"color":_vm.colorTheme.button,"dark":""},on:{"click":_vm.back}},[_vm._v(" Back ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }