<template>
  <v-container fluid>
    <v-card-title> Full Status History </v-card-title>
    <v-card>
      <v-card-title> Latest Status </v-card-title>
      <v-data-table
        fixed-header
        class="scrollable-table__medium"
        hide-default-footer
        :headers="invoiceHeaders"
        :items="latestStatus"
        :loading="loading"
        item-key="id"
      >
        <template v-slot:progress>
          <v-progress-linear
            :color="colorTheme.progress"
            indeterminate
          ></v-progress-linear>
        </template>
        <template v-slot:[`item.billingPeriod`]="{ item }">
          <span class="no-wrap">
            {{ formatPeriod(item.billingPeriod) }}
          </span>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <cell-monetary-value :value="item.amount" />
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <cell-clipboard :text="item.id" />
        </template>
        <template v-slot:[`item.accountInvoiceLineItemId`]="{ item }">
          <cell-clipboard :text="item.accountInvoiceLineItemId" />
        </template>
        <template v-slot:[`item.sourceAccountId`]="{ item }">
          <cell-clipboard :text="item.sourceAccountId" />
        </template>
        <template v-slot:[`item.paidByAccountId`]="{ item }">
          <cell-clipboard :text="item.paidByAccountId" />
        </template>
      </v-data-table>
    </v-card>
    <v-card v-if="latestNote.length > 0">
      <v-card-title> Latest Note </v-card-title>
      <v-data-table
        fixed-header
        class="scrollable-table__medium"
        hide-default-footer
        :headers="noteHeaders"
        :items="latestNote"
        :loading="loading"
        item-key="id"
      >
        <template v-slot:progress>
          <v-progress-linear
            :color="colorTheme.progress"
            indeterminate
          ></v-progress-linear>
        </template>
        <template v-slot:[`item.billingStatusNoteId`]="{ item }">
          <cell-clipboard :text="item.billingStatusNoteId" />
        </template>
        <template v-slot:[`item.eventTime`]="{ item }">
          <td>{{ formatDateTextMonth(item.eventTime) }}</td>
        </template>
      </v-data-table>
    </v-card>
    <v-card-title class="justify-center"> History of changes </v-card-title>
    <v-timeline class="mb-5">
      <v-timeline-item
        small
        v-for="item in combinedChanges"
        :key="item.id"
        :color="item.color"
        left
      >
        <template v-slot:opposite>
          <v-card-text> {{ getTimeAndAuthor(item) }} </v-card-text>
        </template>
        <v-card v-if="item.operation == 'Insert' || item.operation == 'Delete'">
          <span>
            <v-card-text> {{ item.text }} </v-card-text>
          </span>
        </v-card>
        <v-card v-else-if="item.operation == 'Update'">
          <span v-for="(change, index) in item.changes" :key="index">
            <v-card-text>
              {{ change.column }} : {{ change.oldValue }}
              <span :style="transitionColor"> => </span> {{ change.newValue }}
            </v-card-text>
            <v-divider></v-divider>
          </span>
        </v-card>
      </v-timeline-item>
    </v-timeline>
    <v-row>
      <v-btn class="mb-2 ml-2" :color="colorTheme.button" dark @click="back">
        Back
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  formatMonetaryValue,
  formatPeriod,
  formatDateTextMonth,
  formatDate
} from "@/utils/format-util.js";

import CellClipboard from "@/components/common/CellClipboard.vue";
import CellMonetaryValue from "@/components/common/CellMonetaryValue.vue";

export default {
  components: {
    CellClipboard,
    CellMonetaryValue,
  },
  data: () => ({
    loading: false,
    invoiceHistoryItems: [],
    notesHistoryItems: [],
    combinedChanges: [],
    latestStatus: [],
    latestNote: [],
    invoiceHeaders: [
      {
        text: "Account Invoice ID",
        value: "accountInvoiceLineItemId",
        sortable: false,
      },
      {
        text: "Billing Period",
        value: "billingPeriod",
        sortable: false,
      },
      {
        text: "Amount",
        value: "amount",
        align: "end",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
      },
      {
        text: "Source Account ID",
        value: "sourceAccountId",
        sortable: false,
      },
      {
        text: "Paid By Account ID",
        value: "paidByAccountId",
        sortable: false,
      },
      {
        text: "Billing Frequency",
        value: "billingFrequency",
        sortable: false,
      },
      {
        text: "Custodian",
        value: "custodian",
        sortable: false,
      },
    ],
    noteHeaders: [
      {
        text: "Note ID",
        value: "billingStatusNoteId",
        sortable: false,
      },
      {
        text: "Note",
        value: "note",
        sortable: false,
      },
      {
        text: "Author",
        value: "author",
        sortable: false,
      },
      {
        text: "Note info",
        value: "noteOperation",
        sortable: false,
      },
      {
        text: "Date",
        value: "eventTime",
        sortable: false,
      },
    ]
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme", "statusColors"]),
    ...mapState("invoiceScreen", ["selectedInvoices"]),
    transitionColor() {
      return {
        color: this.statusColors.transition,
        "font-weight": "bolder",
      };
    },
  },
  methods: {
    ...mapActions("fullStatusHistory", ["getInvoiceHistory"]),
    async refresh() {
      this.loading = true;
      let result = await this.getInvoiceHistory();
      if (!result) {
        return;
      }

      this.invoiceHistoryItems = result.invoiceHistoryItems;
      this.notesHistoryItems = result.notesHistoryItems;
      this.invoiceHistoryItems.sort((x, y) =>
        y.eventTime.localeCompare(x.eventTime)
      );
      this.notesHistoryItems.sort((x, y) =>
        y.eventTime.localeCompare(x.eventTime)
      );
      this.setChangesItems();
      this.latestStatus = [this.invoiceHistoryItems[0]];
      if (
        this.notesHistoryItems.length > 0 &&
        this.notesHistoryItems[0].operation !== "Delete"
      ) {
        this.latestNote = [this.notesHistoryItems[0]];
      }
      this.loading = false;
    },
    setChangesItems() {
      let invoiceArray = this.invoiceHistoryItems;
      let invoiceChanges = [];
      let noteArray = this.notesHistoryItems;
      let noteChanges = [];
      for (let i = 0; i < invoiceArray.length; i++) {
        invoiceArray[i].changes = [];
        for (const [key, value] of Object.entries(invoiceArray[i])) {
          if (invoiceArray[i].operation == "Insert") {
            invoiceArray[i].text = "Initial Status: " + invoiceArray[i].status;
            invoiceArray[i].color = this.statusColors.initial;
            invoiceArray[i].changes.push({
              operation: invoiceArray[i].operation,
            });
          } else if (invoiceArray[i].operation == "Update") {
            if (
              key != "changes" &&
              key != "id" &&
              key != "accountInvoiceLineItemId" &&
              key != "author" &&
              key != "eventTime" &&
              key != "operation" &&
              key != "color" &&
              invoiceArray[i + 1] != null &&
              value != invoiceArray[i + 1][key]
            ) {
              invoiceArray[i].changes.push({
                column: key.charAt(0).toUpperCase() + key.slice(1),
                oldValue: invoiceArray[i + 1][key],
                newValue: value,
                operation: invoiceArray[i].operation,
              });
              invoiceArray[i].color = this.statusColors.update;
            }
          } else if (invoiceArray[i].operation == "Delete") {
            invoiceArray[i].text = "Invoice deleted";
            invoiceArray[i].color = this.statusColors.delete;
            invoiceArray[i].changes.push({
              operation: invoiceArray[i].operation,
            });
          }
        }
        if (invoiceArray[i].changes.length > 0) {
          invoiceChanges.push(invoiceArray[i]);
        }
      }
      for (let i = 0; i < noteArray.length; i++) {
        noteArray[i].changes = [];
        for (const [key, value] of Object.entries(noteArray[i])) {
          if (noteArray[i].operation == "Insert") {
            noteArray[i].text = "Note created: " + noteArray[i].note;
            noteArray[i].color = this.statusColors.initial;
            noteArray[i].changes.push({
              operation: noteArray[i].operation,
            });
          } else if (noteArray[i].operation == "Update") {
            if (
              key != "changes" &&
              key != "id" &&
              key != "invoiceId" &&
              key != "author" &&
              key != "billingStatusNoteId" &&
              key != "eventTime" &&
              key != "operation" &&
              key != "color" &&
              noteArray[i + 1] != null &&
              value != noteArray[i + 1][key]
            ) {
              noteArray[i].changes.push({
                column: key.charAt(0).toUpperCase() + key.slice(1),
                oldValue: noteArray[i + 1][key],
                newValue: value,
                operation: noteArray[i].operation,
              });
              noteArray[i].color = this.statusColors.update;
            }
          } else if (noteArray[i].operation == "Delete") {
            noteArray[i].text = "Note deleted: " + noteArray[i].note;
            noteArray[i].color = this.statusColors.delete;
            noteArray[i].changes.push({
              operation: noteArray[i].operation,
            });
          }
        }
        if (noteArray[i].changes.length > 0) {
          noteChanges.push(noteArray[i]);
        }
      }
      this.combinedChanges = invoiceChanges;
      noteChanges.forEach(note => {
        this.combinedChanges.push(note);
      });
      this.combinedChanges.sort((x, y) => y.eventTime.localeCompare(x.eventTime));
    },
    formatMonetaryValue(number) {
      return formatMonetaryValue(number);
    },
    getTimeAndAuthor(item) {
      var eventDate = formatDate(item.eventTime)
      if (item.author) {
        return `${eventDate}, ${item.author}`;
      } else {
        return eventDate;
      }
    },
    getTimeAgo(dateString) {
      var message;
      var milliSeconds, seconds, minutes, hours, days, weeks;
      let time = Date.parse(dateString);
      let currentTime = Date.parse(
        new Date(new Date().toUTCString().substr(0, 25))
      );
      milliSeconds = currentTime - time;
      seconds = Number.parseInt(milliSeconds / 1000);
      if (seconds < 60) {
        seconds == 1
          ? (message = seconds + " second ago")
          : (message = seconds + " seconds ago");
        return message;
      }
      minutes = Number.parseInt(seconds / 60);
      if (minutes < 60) {
        minutes == 1
          ? (message = minutes + " minute ago")
          : (message = minutes + " minutes ago");
        return message;
      }
      hours = Number.parseInt(minutes / 60);
      if (hours < 24) {
        hours == 1
          ? (message = hours + " hour ago")
          : (message = hours + " hours ago");
        return message;
      }
      days = Number.parseInt(hours / 24);
      if (days < 7) {
        days == 1
          ? (message = days + " day ago")
          : (message = days + " days ago");
        return message;
      }
      weeks = Number.parseInt(days / 7);
      if (weeks < 5) {
        weeks == 1
          ? (message = weeks + " week ago")
          : (message = weeks + " weeks ago");
        return message;
      }
      return this.formatLongDateTime(dateString);
    },
    formatLongDateTime(dateTime) {
      let date = new Date(dateTime).toString();
      var words = date.split(" ");
      return (
        words[0] +
        " " +
        words[1] +
        " " +
        words[2] +
        " " +
        words[3] +
        " " +
        words[4]
      );
    },
    formatPeriod(period) {
      return formatPeriod(period);
    },
    formatDateTextMonth(dateTime) {
      return formatDateTextMonth(dateTime);
    },
    reset() {
      this.invoiceHistoryItems = [];
      this.notesHistoryItems = [];
      this.combinedChanges = [];
      this.latestStatus = [];
      this.latestNote = [];
    },
    back() {
      this.reset();
      this.$router.push({ name: "InvoiceScreen" });
    },
  },
  async mounted() {
    if (this.selectedInvoices.length == 0) {
      this.back();
    } else {
      await this.refresh();
    }
  },
};
</script>
